/* Spacer */
.spacer10 {
    height: 10px;
    background: transparent;
}

.spacer20 {
    height: 20px;
    background: transparent;
}

.spacer30 {
    height: 30px;
    background: transparent;
}

/* Cards */
.basicCardContainer {
   height: 100px;
    margin: 0 auto;

}


.appCardContainer {
  margin: 0 auto;
}

.appCardContainer a{
  text-decoration: none;
  color: #fff;
}

.dashboardImageCardContainer {
  background: #283d9b;
  height: 275px;
  width: 100%;
}

.dashboardImageCardContainer  .content {
  background: #142364;
  clip-path: ellipse(165% 80% at 75%  0% );
  height: 275px;
  width: 100%;
}

.dashboardCardContainer {
  margin: 0 auto;
  width: 100%;
  height: 300px;
  
}

.dashboardCardContainer  .MuiCard-root{
 
  background: linear-gradient(to bottom, #223692, #3f58c6);
}

.adminCardContainer {
  margin: 0 auto;
  width: 100%;

}

.adminCardContainer .MuiCard-root {
  background: linear-gradient(to bottom, #0f183f, #172254);
}

.adminCardContainer a {
  text-decoration: none;
}

.imageCardContainer {
    margin: 0 auto;
    width: 100%;

}

.modelRegistryCardContainer {
  margin: 0 auto;
  font-size: 22px;
}

.modelRegistryCardContainer .MuiCard-root{
  background: linear-gradient(to bottom, #ffffff, #e5e9f8);
}

/* Alignment */
.rightAlign {
    text-align: right;
    margin-top: 17px;
    padding-right: 30px;
}

/* Typography */
.pageTitle {
    font-size: 22px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; 
    color: #bac7fe;
    font-style: italic;
  
}

/* Divicer */

.divider {
    float: left;
    width: 95%;
    height: 1px;
    
    background-image: linear-gradient(
      to right,
      transparent,
      #a4b2f0,
      transparent
    );
  }

  .dividerDotted {
    margin: $dividerShapeMargin;
    width: 85%;
    border: 2px dashed #98a6e2;
    opacity: 0.1;
  }

  // Pagination

//curved background
.pagination {
    margin: 10px auto;
    background: #dbdfff;
    padding: 10px;
    border-radius: 7px 7px 0 0;
    width: 60%;
  }
  
  
  //list items


  .adminList {
    color: #1d2960;
    border-radius: 15px;
    height: 150px;
  }


  .adminList .MuiAvatar-root{
    width: 75px;
    height: 75px;
    margin: 0 auto;
    text-align: center;
  }

  .adminList .content {
    margin-top:50px;
  }


  .pagination > li {
    display: inline-block;
    padding-left: 12px;
    text-align: center;
    font-size: large;
    justify-content: center;
    font-family: monospace;
    list-style: none;
    margin-left: 5px;
    color: #525dc2;
    }
  
  
  
  .active {
    color: #8a9ffd;
    font-weight: bold;
    font-family: monospace;
  }

  /* Banner Fade */
  .bannerFade {
    background: linear-gradient(to right, #6384f2, #ffffff);
    
    padding: 5px;
    margin-left: -15px;
  }

  /* Tables */
  .table {
    background: #26387a;
    color: #fff;
    border-radius: 10px;
  }

 

  /* Text Field */
  .inputField {
    background: #fff;
    border-radius: 10px;
    width: 100%;
  }

  /* Dialog */
  // .MuiDialog-paperFullScreen {
    
    
  // }

  /* Links */
  .links {
    color: #bbc8ff;
    font-size: 20px;
    float: right;
 
  }

  .links a {
    text-decoration: none;
    color: #7b92f9;
    font-size: 20px;
  
  }

  .links a:hover {
    text-decoration: none;
    color: #e9edff;

    width: 100%;
    font-size: 20px;

  }
  .menuItem a {
    width: 100%;
    padding: 5px;
  }

  .menuItem a:hover {
    width: 100%;
    border-radius: 5px;
    background: #d3dbff;
    padding: 5px;
  }

  /* Buttons */
  .btnOutlined:hover {
    background: #3f58c6;
  }
  