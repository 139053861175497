@import "./variables.scss";


body {
  background: linear-gradient(to bottom, #0f183f, #080e27);
  margin: $appContainerBodyMargin;

}

.appContainer {
    height: $appContainerHeight;
    
  }
  
  /* Header Container */
  .headerContainer {
    height: $headerContainerHeight;
    padding-top: $headerContainerPaddingTop;
    padding-left: $headerContainerPaddingLeft;
    background: $headerContainerBackgroundColor;
  }
  
  .headerContainer img {
    margin-top: 0;
    padding-top: $headerContainerImgPaddingTop;
    margin-left: -70px;
    height: $headerContainerImgHeight;
    width: $headerContainerImgWidth;
    float: $headerContainerImgFloat;
  }

  /* Footer */

  .footerContainer{
    margin: 25px auto;
    padding-bottom: 50px;
   

  }

  .footerContainer img{
    width: 150px;
 
  }

  /* Template Main Container */
  .contentContainer {
    height: 100%;
   

  }

  /* Dashboard Container */
  .dashboardContainer {
    margin: 30px auto;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .dashboardContainer a {
    text-decoration: none;
  }

  .dashboardContainer .content {
    margin: 0 auto;
  }

  .dashboardContainer .MuiTypography-h5 {
    color: #213489;
    font-size: 18px;
  }

  .dashboardContainer img{
    height: 200px;
  }

   /* Tools Container */
   .toolsPageContainer {
    margin: 30px auto;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    color: #fff;
  }

  .toolsPageContainer .MuiCard-root{
    height: 350px;
    background: #222f6b;
  }

  /* Workflow Container */
  .workflowContainer {
    margin: 50px auto;
  
  }

  /* Data Catalog Cotainer */
  .dataCatalogContainer {
    margin: 15px auto;

  }

  .dataCatalogContainer .searchBox {
    background: $searchBoxBackgroundColor;
    border-radius: 10px;
 
  }

  .dataCatalogContainer .searchBox .searchButton {
    background: #3541ac;
    color: #ffffff;
    margin-top: 0px;
    margin-right: 10px;
    float: right;
  }

  .dragDropArea {
    background: linear-gradient(to bottom, #0f183f, #0f183f);
    height: 400px;
 
  }


   /* Natural Language Cotainer */
   .natLangSearchContainer {
    margin: 50px auto;
 
    }

    .natLangSearchContainer .searchBox {
      background: $searchBoxBackgroundColor;
      border-radius: 10px;
      margin: 0 auto;
    
    }

    .natLangSearchContainer .searchBox .searchButton {
      background: #2f388e;
      color: #aafbf2;
      margin-top: 5px;
      margin-right: 10px;
      float: right;
    }

    /* NLP Upload */
    .nlpUploadContainer {
      margin: 50px auto;
   
      }

    /* List */ 
    .listContainer {
      background: linear-gradient(to right, #202e63, $primary);
      border-radius: 5px;
    
    }

    // Upload Data Page

    .formListTextbox {
    
      background-color: #ffffff;
      border-radius: 5px;
    }

    .formListTypography {
      color: #a1b7ff;
      margin-top: 15px;
      float: left;
      padding-bottom: 10px;
    }

    .message {
      background: linear-gradient(to bottom, #ccd7ff, #a9b9fe);
      color: #181d35;
      margin: 0 auto;
      text-align: left;
      font-size: 18px;
      border-radius: 20px;
      padding:  20px;
      width: 85%;

    }

    /* Governance */
    .governanceSection {
      background: linear-gradient(to bottom, #0c122d, #0c122d);
      padding-bottom: 25px;
      padding-left: 10px;
    }


    /* Tools */
    .toolsContainer {
      height: 75vh;
      margin: 0 auto;
      color: #445ed4;
    }
    
 