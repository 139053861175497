/* Sizing */
$desktopResWidth: 800px;
$tabletResWidth: 700px;
$mobileResWidth: 600px;

/* Pallette */
$black: #000000;
$white: #ffffff;
$grey: #f8f7f7;

$primary: #0f183f;
$background: $grey;
$highliteFontColor: #63f2eb;
$backgroundTableColor: #9a9a9a;
$tableHighliteColor: #c6fff8;
$ankuraGreen: #aafbf2;

//SAIC Color Palate
$SAICBlue: #006bb6;
$SAICLiteBlue: #929dff;
$SAICGreen: #bcd63e;
$SAICGrey: #64696f;
$SAICLiteGrey: #e1e1e1;

//Div Spacing
$spacingWidth: 100%;
$spacingHeight: 20px;

//Margin
$marginCenter: 0 auto;
$marginLeft: 15px;

/* Type Face */
$titleFont: Inter;
$tableFont: Rift;
@import url("https://fonts.google.com/specimen/Inter");
@import url("https://fonts.adobe.com/fonts/rift");

/* Containers */
/* App Container */
$appContainerHeight: 100%;
$appContainerBodyMargin: 0;

/* Header Container */
$headerContainerHeight: 75px;
$headerContainerPaddingTop: 1px;
$headerContainerPaddingLeft: 10px;
$headerContainerBackgroundColor: linear-gradient(to right, #081544, #2f388e);
$headerContainerImgMarginTop: 5px;
$headerContainerImgMarginLeft: 55px;
$headerContainerImgWidth: auto;
$headerContainerImgHeight: 65px;
$headerContainerImgPaddingTop: 5px;
$headerContainerImgFloat: left;

/* Content Container */
$contentContainerBckGrd: transparent;
$contentContainerHeight: 100%;
$contentContainerPadding: 18px;
$contentContainerMargin: 0 auto;
$contentContainerPaddingTop: 3px;

// Footer Container
$footerBackground: linear-gradient(to right, #00acd4, #006bb6);
$footerHeight: 150px;

/* Menu Container */
$menuIconButtonColor: $white;
$menuContainerFontSize: 17px;
$menuContainerTextDecoration: none;
$menuContainerTextAlign: center;
$menuContainerHoverBackground: #a0a0a0;

/* Divider */
$dividerShapeMargin: 0 auto;
$dividerShapeWidth: 95%;
$dividerShapeHeight: 15px;
$dividerShapeSkew: skew(-5deg);
$dividerShapeBckgrdImg: linear-gradient(to right, $primary, #0b80d4);
$dividerShapeHeight: 0.5px;

/* Search Page */
$searchPageContainerMargin: 5px auto;
$searchPageContainerHeight: 100%;
$searchPageContainerPaddingBottom: 175px;
$searchBodyMargin: 0 auto;
$searchBodyHeight: 100%;
$searchBodyPaddingBottom: 75px;
$searchBoxBackgroundColor: linear-gradient(to right, #eff2ff, #ffffff);

/* Search */
$searchIconWidth: 100%;
$searchIconHeight: 100%;
$searchMainDisplay: flex;
$searchMainJustifyContent: center;
$searchMainAlignItems: center;
$searchBoxBorderRadius: 7px;
$searchBoxShadow: 1px 3.5px 0px rgba(177, 177, 177, 0.4);

/* Search Button */
$searchButtonDisplay: flex;
$searchButtonJustinifyContent: center;
$searchButtonMarginTop: 5px;
$searchButtonBackgroundColor: $SAICGreen;
$searchButtonMarginRight: 25px;
$searchButtonHeight: 40px;

/* Table */
$tableBodyTextAlign: left;
$tableBodyBackgroundHover: #ebf5fd;
$tableHeadFontStyle: bold;
$tableHeadBackground: #016bb5;
$tableHeadColor: $white;

/* Alignment */
$centerAlignText: center;
$rightAlignPaddingRight: 35px;
$rightAlignText: right;
